<template>
  <div class="DCI">
        <div class="banner">
            <div class="container bannerContainer">
            <div>
                <p><strong>申领DCI快捷确认权属</strong></p>
                <span>基于数字作品版权链(DCI体系3.0)全面标准化升级，帮助原创作者便捷申领 DCI</span>
                <router-link to="/DCIInputForm">申领DCI</router-link>
            </div>
            <img class="bannerBg" src="../../assets/images/DCIBanner2.png" alt="">
            </div>
        </div>

        <div id="content1" class="content1">
            <div class="container">
                <div class="title">DCI 体系</div>
                <h3>DCI（Digital Copyright Identifier，数字作品版权唯一标识符）体系是中国版权保护中心按照《著作权法》、“数字作品版权唯一标识符”标准及相关规定对符合要求的作品分配的一组字符，用于唯一标识数字作品版权。以规范高效的版权公共服务体系与机制为保障，面向互联网平台以共生、共治、共享的理念，共建互联网版权基础设施，打造互联网版权新生态</h3>
                <div class="content1Box">
                    <a-row>
                        <a-col class="content1L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIBg01.png" alt=""><p>版权确权</p><span>联合知产中心，开具知识产权存证证书</span></div>
                        </a-col>
                        <a-col class="content1L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIBg02.png" alt=""><p>侵权取证</p><span>一键固定侵权证据，可信有效</span></div>
                        </a-col>
                        <a-col class="content1L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIBg03.png" alt=""><p>侵权监测</p><span>线上、线下侵权线索全监测</span></div>
                        </a-col>
                        <a-col class="content1L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIBg04.png" alt=""><p>数据存证</p><span>链上存证，信息不可篡改</span></div>
                        </a-col>
                        <a-col class="content1L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIBg05.png" alt=""><p>司法出证</p><span>权威联盟见证，证据公证保全</span></div>
                        </a-col>
                        <a-col class="content1L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIBg06.png" alt=""><p>法律服务</p><span>律所、司法鉴定、多种维权方式、省心安心</span></div>
                        </a-col>
                    </a-row>
                    <div class="content1a"><router-link to="/DCIInputForm">立即申领DCI</router-link></div>
                </div>
            </div>
        </div>

        <div id="content2" class="content2">
            <div class="container">
                <div class="title">DCI核心业务功能</div>
                <h3>利用区块链和大数据、人工智能等技术，对数字作品版权进行保护，提供用户注册、DCI登记服务、DCI授权服务和DCI维权服务</h3>
                <div class="content2Box">
                    <a-row>
                        <a-col class="content2L" :md="24" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg08.png" alt=""><p>权属确认</p><span>权属确认效率高，DCI申领秒级反馈，实时获得DCI</span></div>
                        </a-col>
                        <a-col class="content2L" :md="24" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg09.png" alt=""><p>授权结算</p><span>智能算法审核快，人工智能精准识别作品版权权属信息</span></div>
                        </a-col>
                        <a-col class="content2L" :md="24" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg10.png" alt=""><p>维权保护</p><span>权威保护，凭DCI可申请国家版权局作品登记证书</span></div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>

        <div id="content3" class="content3">
            <div class="container">
                <div class="title">DCI 申领让权属确认更专业权威</div>
                <div class="content3Box">
                    <a-row>
                        <a-col class="content3L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg01.png" alt=""><p>符合DCI体系3.0标准</p></div>
                        </a-col>
                        <a-col class="content3L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg02.png" alt=""><p>符合《著作权法》及相关规定</p></div>
                        </a-col>
                        <a-col class="content3L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg03.png" alt=""><p>作品独创性核查/查重建议</p></div>
                        </a-col>
                        <a-col class="content3L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg04.png" alt=""><p>支持争议阶段司法核验</p></div>
                        </a-col>
                        <a-col class="content3L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg05.png" alt=""><p>权属确认证明</p></div>
                        </a-col>
                        <a-col class="content3L" :md="12" :lg="8" :xl="8">
                            <div><img src="../../assets/images/DCIImg06.png" alt=""><p>用户行为存证</p></div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>

        <div id="content4" class="content4">
            <div class="container">
                <div class="content4Box">
                    <a-row>
                        <a-col class="content4L" :md="24" :lg="12" :xl="12">
                            <div><p>即时申领DCI</p><p>按需办理数字作品版权登记证书</p><span>凭DCI可申请国家版权局作品登记证书</span><router-link to="/DCIInputForm">立即申领DCI</router-link></div>
                        </a-col>
                        <a-col class="content4R" :md="24" :lg="12" :xl="12">
                            <div><img src="../../assets/images/DCIImg07.png" alt=""></div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>

        <div id="content5" class="content5">
            <div class="container">
                <div class="title"><p>DCI 体系3.0标准升级 快速完成作品权属确认</p></div>
                <p class="blcok8text">权属确认｜授权结算｜维权保护</p>
                <div class="block8Btn"><router-link to="/Register">版权登记</router-link><router-link to="/DCIInputForm">DCI申领</router-link></div>
            </div>
        </div>

  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.banner{
  width: 100%;
  height: 440px;
  position: relative;
  background: url(../../assets/images/DCIBannerBg.png)no-repeat;
  background-size: cover;
  .bannerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      p{
        font-size: 48px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        font-weight: 400;
        color: #000000;
        line-height: 81px;
        strong{
          color: #056AE2;
        }
      }
      span{
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
        font-family: 'SourceHanSansCN, SourceHanSansCN';
        color: #333763;
        padding-right: 20px;
      }
      a{
          display: inline-block;
          height: 42px;
          background: linear-gradient(90deg, #0F4893 0%, #0380E1 100%);
          border-radius: 4px;
          line-height: 42px;
          padding: 0 20px;
          margin-top: 20px;
          color: #FFFFFF;
          font-size: 18px;
          font-family: 'SourceHanSansCN, SourceHanSansCN';
      }
      a:hover{
          background: linear-gradient(90deg, #0380E1 0%, #0F4893 100%);
      }
    }
    img{
      height: 400px;
      margin-top: 40px;
    }
  }
}
.title{
  font-size: 36px;
  font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
  font-weight: 500;
  color: #252D47;
  line-height: 54px;
  text-align: center;
}
.content1{
    background: #FFFFFF;
    padding: 60px 0;
    h3{
        font-size: 16px;
        font-family: 'SourceHanSansCN, SourceHanSansCN';
        font-weight: 400;
        color: #697081;
        line-height: 26px;
        text-align: center;
        margin: 20px 0;
    }
    .content1Box{
        .content1L{
            div{
                height: 116px;
                background: linear-gradient(90deg, #E5F2FF 0%, #FAFBFF 100%);
                box-shadow: 0px 0px 10px 0px rgba(5,120,214,0.1);
                border-radius: 4px;
                border: 2px solid #FFFFFF;
                margin: 10px 5px;
                padding: 30px 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                img{
                    height: 60px;
                    position: absolute;
                    left: 30px;
                    top: 26px;
                }
                p{
                    font-size: 16px;
                    font-family: 'SourceHanSansCN, SourceHanSansCN';
                    font-weight: 500;
                    color: #000000;
                }
                span{
                    font-size: 16px;
                    font-family: 'SourceHanSansCN, SourceHanSansCN';
                    color: #666666;
                    line-height: 22px;
                    display: inline-block;
                    width: 180px;
                }
            }
            div:hover{
                background: linear-gradient(90deg, #0F4792 0%, #018AEF 100%);
                box-shadow: 0px 0px 11px 0px rgba(22,94,154,0.1);
                p{
                    color: #FFFFFF;
                }
                span{
                    color: #86CDFF;
                }
            }
        }
        .content1a{
            text-align: center;
            margin-top: 20px;
            a{
                display: inline-block;
                height: 42px;
                background: linear-gradient(90deg, #0F4893 0%, #0380E1 100%);
                border-radius: 21px;
                line-height: 42px;
                padding: 0 20px;
                color: #FFFFFF;
                font-size: 16px;
                font-family: 'SourceHanSansCN, SourceHanSansCN';
                margin: 0 auto;
            }
            a:hover{
                background: linear-gradient(90deg, #0380E1 0%, #0F4893 100%);
            }
        }
    }
    
}
.content2{
    background: linear-gradient(90deg, #F4F8FF 0%, #ECF3FF 50%, #EEF7FC 100%);
    padding: 60px 0;
    h3{
        font-size: 16px;
        font-family: 'SourceHanSansCN, SourceHanSansCN';
        font-weight: 400;
        color: #697081;
        line-height: 26px;
        text-align: center;
        margin: 20px 0;
    }
    .content2Box{
        .content2L{
            margin-top: 10px;
            div{
                background: linear-gradient(180deg, #F6F9FF 0%, #EBF6FE 100%);
                box-shadow: 0px 8px 15px 0px rgba(0,62,241,0.08);
                border-radius: 16px;
                border: 1px solid #FFFFFF;
                margin: 0 50px;
                padding: 20px 56px;
                text-align: center;
                img{
                    height: 116px;
                    margin-bottom: 20px;
                    transition: transform ease-in-out .2s;
                }
                img:hover{
                    transform: translateY(-10px);
                }
                p{
                    font-size: 26px;
                    font-family: 'SourceHanSansCN, SourceHanSansCN';
                    font-weight: 500;
                    color: #333333;
                    line-height: 30px;
                    margin-bottom: 10px;
                }
                span{
                    font-size: 16px;
                    font-family: 'SourceHanSansCN, SourceHanSansCN';
                    color: #666666;
                    line-height: 24px;
                }
            }
        }
    }

}
.content3{
    background: url(../../assets/images/DCIBlock3Bg.png)no-repeat;
    background-size: cover;
    padding: 60px 0;
    .title{
        color: #FFFFFF;
    }
    .content3Box{
        margin-top: 30px;
        .content3L{
            div{
                height: 150px;
                background: rgba(97,145,243,0.4);
                border-radius: 12px;
                border: 1px solid #739EFF;
                margin: 15px 30px;
                padding: 40px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img{
                    width: 75px;
                    height: 72px;
                }
                p{
                    width: 180px;
                    font-size: 22px;
                    font-family: 'SourceHanSansCN, SourceHanSansCN';
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 34px;
                }
            }
        }
    }
}
.content4{
    background: #F9FBFF;
    padding: 30px 0 60px 0;
    .content4L{
        padding: 70px 0;
        p{
            font-size: 44px;
            font-family: 'SourceHanSansCN, SourceHanSansCN';
            font-weight: 500;
            color: #000000;
            line-height: 76px;
            letter-spacing: 1px;
        }
        span{
            font-size: 24px;
            font-family: 'SourceHanSansCN, SourceHanSansCN';
            font-weight: 500;
            color: #252D47;
            line-height: 36px;
            display: block;
        }
        a{
          display: inline-block;
          height: 42px;
          background: linear-gradient(90deg, #0F4893 0%, #0380E1 100%);
          border-radius: 21px;
          line-height: 42px;
          padding: 0 20px;
          margin-top: 20px;
          color: #FFFFFF;
          font-size: 16px;
          font-family: 'SourceHanSansCN, SourceHanSansCN';
          margin-top: 40px;
        }
        a:hover{
            background: linear-gradient(90deg, #0380E1 0%, #0F4893 100%);
        }
    }
    .content4R{
        div{
            padding: 0 20px;
            img{
                width: 100%;
            }
        }
    }
}
.content5{
  background: url(../../assets/images/DCIBlock5Bg.png)no-repeat;
  background-size: cover;
  padding: 60px 0;
  .title{
      margin-bottom: 30px;
    p{
      font-size: 40px;
      font-family: 'MicrosoftYaHei';
      font-weight: bold;
      color: #FFFFFF;
      line-height: 48px;
    }
  }
  .blcok8text{
    font-size: 14px;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #97A7ED;
    line-height: 20px;
    letter-spacing: 10px;
    text-align: center;
    margin-bottom: 30px;
  }
  .block8Btn{
    display: flex;
    align-items: center;
    justify-content: center;
    a{
      display: block;
      width: 90px;
      height: 32px;
      line-height: 30px;
      border: 1px solid #FFFFFF;
      font-size: 14px;
      font-family: 'Microsoft YaHei';
      color: #FFFFFF;
      text-align: center;
      border-radius: 4px;
      margin: 0 8px;
    }
  }
}

// 适配移动端
@media screen and (max-width: 991px){
  .banner{
    height: 250px;
    div{
        padding: 20px 10px;
      p{
        font-size: 26px!important;
        line-height: 60px!important;
      }
      span{
        margin-top: 0!important;
        line-height: 30px!important;
        padding-right: 0!important;
      }
    }
    .bannerBg{
        display: none;
    }
  }
  .title{
      font-size: 26px;
  }
  .content4 .content4L{
      padding: 0;
      p{
          font-size: 26px;
          line-height: 40px;
      }
      span{
          font-size: 16px!important;
      }
      a{
          margin-top: 20px!important;
      }
  }
  .content5 .title p{
      font-size: 26px;
  }
}
</style>
